import { IMaskInput } from "react-imask"

type InvoiceNumberInputProps = {
  value?: string
  onValueChange(value: string): void
}

export function InvoiceNumberInput({
  value,
  onValueChange,
}: InvoiceNumberInputProps) {
  return (
    <IMaskInput
      className="bg-gray-0 placeholder-gray-3 w-full rounded p-4 text-base"
      mask="0000-0000-0000-0000"
      type="search"
      inputMode="numeric"
      placeholder="운송장번호를 입력해 주세요."
      value={value}
      onAccept={(value) => onValueChange(value as string)}
    />
  )
}
