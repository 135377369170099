import Link from "next/link"
import { useRouter } from "next/router"
import { FormEvent, useCallback, useEffect, useState } from "react"
import { InvoiceNumberInput, SearchError } from "."
import { CtaButton } from ".."
import CallCenterIcon from "../../public/images/call-center-icon.svg"

type SearchSectionProps = {
  title: string
  description?: string
  defaultInvoiceNumber?: string
  showCallCenter?: boolean
  onSubmit?(): void
}

export function SearchSection({
  title,
  description,
  defaultInvoiceNumber,
  showCallCenter = false,
  onSubmit,
}: SearchSectionProps) {
  const router = useRouter()
  const [invoiceNumber, setInvoiceNumber] = useState("")
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const submit = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      const plainNumber = invoiceNumber.replace(/-/g, "")
      if (plainNumber.length === 12 || plainNumber.length === 13) {
        setErrorMessage(undefined)
        router.push(`/track/${plainNumber}`)
        onSubmit?.()
      } else {
        setErrorMessage("운송장 번호는 12자리 혹은 13자리입니다.")
      }
    },
    [router, invoiceNumber, onSubmit]
  )
  useEffect(() => {
    if (defaultInvoiceNumber) {
      setInvoiceNumber(defaultInvoiceNumber)
    }
  }, [defaultInvoiceNumber])
  return (
    <div className="flex flex-col bg-white px-6 pb-6 pt-8">
      <div className="whitespace-pre-line text-[1.375rem] font-bold">
        {title}
      </div>
      {description && <div className="mt-2">{description}</div>}
      <div className="h-4" />
      {showCallCenter && (
        <>
          <Link href="tel:1544-6213">
            <a className="flex w-fit items-center gap-x-1.5">
              <CallCenterIcon />
              <span className="text-blue text-sm font-semibold underline underline-offset-4">
                고객센터 문의하기
              </span>
            </a>
          </Link>
          <div className="mb-4 mt-10 text-sm">다른 번호 조회하기</div>
        </>
      )}
      <form onSubmit={submit}>
        <InvoiceNumberInput
          value={invoiceNumber}
          onValueChange={setInvoiceNumber}
        />
        <div className="h-2" />
        <SearchError message={errorMessage} />
        <div className="h-8" />
        <CtaButton type="submit">조회하기</CtaButton>
      </form>
    </div>
  )
}
