import { useState } from "react"
import BackIcon from "../../public/images/back-icon.svg"
import CheckedIcon from "../../public/images/radio-checked-icon.svg"
import UncheckedIcon from "../../public/images/radio-unchecked-icon.svg"
import { PrivacyAgreement } from "./PrivacyAgreement"

type PrivacyCheckboxProps = {
  checked: boolean
  setChecked: (checked: boolean) => void
}

export function PrivacyCheckbox({ checked, setChecked }: PrivacyCheckboxProps) {
  const [showsAgreement, setShowsAgreement] = useState(false)
  return (
    <>
      <button
        className="border-gray-2 flex items-center border-b py-6 text-left"
        onClick={() => setChecked(!checked)}
      >
        <div className="p-1.5">
          {checked ? <CheckedIcon /> : <UncheckedIcon />}
        </div>
        <div className="ml-2 mr-5 flex-1">
          배송을 위한 개인정보(출입 방법) 수집, 이용에 동의합니다.
        </div>
        <button
          className="flex h-8 w-8 rotate-180 items-center justify-center"
          onClick={(event) => {
            setShowsAgreement(true)
            event.stopPropagation()
          }}
        >
          <BackIcon />
        </button>
      </button>
      <PrivacyAgreement
        isOpen={showsAgreement}
        onClose={() => setShowsAgreement(false)}
        onAccept={() => {
          setChecked(true)
          setShowsAgreement(false)
        }}
      />
    </>
  )
}
