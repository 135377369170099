import CloseIcon from "../../public/images/close-icon.svg"
import { ProgressSteps, ProgressStepsProps } from "./ProgressSteps"

type ProgressDetailBottomSheetProps = {
  progressSteps: ProgressStepsProps
  title: string
  open: boolean
  onDismiss?(): void
}

export function ProgressDetailBottomSheet({
  progressSteps,
  title,
  open,
  onDismiss,
}: ProgressDetailBottomSheetProps) {
  return (
    <div
      className={`absolute top-0 z-50 h-full w-full overflow-hidden transition-all ${
        open ? "visible" : "invisible"
      }`}
    >
      <div
        className={`absolute h-full w-full cursor-pointer bg-black transition duration-300 ${
          open ? "bg-opacity-80" : "bg-opacity-0"
        }`}
        onClick={onDismiss}
      />
      <div
        className={`absolute bottom-0 w-full break-all rounded-t-2xl bg-white transition duration-300 ${
          open ? "translate-y-0" : "translate-y-full"
        }`}
      >
        <button className="absolute right-6 top-6" onClick={onDismiss}>
          <CloseIcon />
        </button>
        <div className="flex flex-col pb-6 pt-8">
          <div className="flex flex-col px-6">
            <div className="text-gray-3 text-lg">배송 상세</div>
            <div className="line-clamp-3 mt-2 text-2xl font-bold">{title}</div>
          </div>
          <div className="bg-gray-0 mt-6 h-2" />
          <ProgressSteps {...progressSteps} />
        </div>
      </div>
    </div>
  )
}
