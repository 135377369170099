type MapBubbleProps = {
  color: "BLUE" | "GRAY" | "GREEN" | "RED" | "INDIGO"
  text: string
}

export function MapBubble({ color, text }: MapBubbleProps) {
  let background: string
  let border: string
  switch (color) {
    case "BLUE":
      background = "bg-blue"
      border = "border-t-blue"
      break
    case "GRAY":
      background = "bg-gray-3"
      border = "border-t-gray-3"
      break
    case "GREEN":
      background = "bg-green"
      border = "border-t-green"
      break
    case "RED":
      background = "bg-red"
      border = "border-t-red"
      break
    case "INDIGO":
      background = "bg-indigo"
      border = "border-t-indigo"
  }
  return (
    <div className="mb-[3px] flex flex-col items-center">
      <div
        className={`rounded-full px-2.5 py-1 text-xs font-bold text-white ${background}`}
      >
        {text}
      </div>
      <div
        className={`h-0 w-0 border-x-[3px] border-t-[5px] border-x-white ${border}`}
      />
    </div>
  )
}
