type SearchErrorProps = {
  message?: string
}

export function SearchError({ message }: SearchErrorProps) {
  return (
    <div
      className={`bg-red flex h-10 w-full items-center rounded px-3 text-sm text-white ${
        message ? "visible" : "invisible"
      }`}
    >
      {message}
    </div>
  )
}
