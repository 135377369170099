import { Location, Station } from "@today/api/tracker"
import { sleepAsync } from "@today/lib"
import { TODAY_INDIGO } from "@today/ui"
import axios from "axios"
import Script from "next/script"
import { useEffect, useState } from "react"
import ReactDOMServer from "react-dom/server"
import { MapBubble } from "."
import DeliIcon from "../../public/images/markers/deli.svg"
import LoadIcon from "../../public/images/markers/load.svg"
import { RouteCoordinatesResponse } from "../../pages/api/routes/from/[fromStationId]/to/[toStationId]"

type MapProps = {
  steps: {
    station: Station
    arrivalTime: string | null
    departureTime: string | null
  }[]
  holdingDelivery: boolean
  delivered: boolean
  senderLocation?: Location
  receiverLocation: Location
  listeners?: {
    onClick?(): void
    onDoubleClick?(): void
    onPinchEnd?(): void
    onDragEnd?(): void
  }
}

export function Map({
  steps,
  holdingDelivery,
  delivered,
  senderLocation,
  receiverLocation,
  listeners,
}: MapProps) {
  const [isMapLoaded, setMapLoaded] = useState(false)
  useEffect(() => {
    if (typeof naver === "undefined") return
    const map = new naver.maps.Map("map", {
      mapDataControl: false,
      scaleControl: false,
    })

    // Add listeners
    naver.maps.Event.addListener(map, "click", () => listeners?.onClick?.())
    naver.maps.Event.addListener(map, "dblclick", () =>
      listeners?.onDoubleClick?.()
    )
    naver.maps.Event.addListener(map, "pinchend", () =>
      listeners?.onPinchEnd?.()
    )
    naver.maps.Event.addListener(map, "dragend", () => listeners?.onDragEnd?.())

    // 정류소 마커
    steps.forEach(({ station, arrivalTime, departureTime }, index) => {
      const isPickUp = station.level === "PU"
      const isLastStation = index === steps.length - 1
      const atStation = !!arrivalTime && !departureTime
      new naver.maps.Marker({
        map,
        position: toNaverLatLng(station.location),
        icon: {
          content: ReactDOMServer.renderToStaticMarkup(
            isPickUp ? (
              <div className="flex w-[100px] flex-col items-center">
                <MapBubble color="BLUE" text="출발지" />
                <div className="border-blue rounded-full border-4 bg-white shadow-md">
                  <div className="h-3 w-3" />
                </div>
              </div>
            ) : (
              <div className="flex w-[100px] flex-col items-center">
                <div className={atStation ? "visible" : "invisible"}>
                  <MapBubble
                    color={holdingDelivery ? "INDIGO" : "GREEN"}
                    text={
                      holdingDelivery
                        ? "배송 보류"
                        : isLastStation
                        ? "곧 출발 예정"
                        : "이동 대기중"
                    }
                  />
                </div>
                <div
                  className={`rounded-full border-4 bg-white shadow-md ${
                    atStation
                      ? "border-green"
                      : arrivalTime
                      ? "border-indigo"
                      : "border-gray-3"
                  }`}
                >
                  {atStation ? <LoadIcon /> : <div className="h-3 w-3" />}
                </div>
                <div
                  className="mt-0.5 text-xs font-black"
                  style={{
                    textShadow:
                      "-2px 0 white, 0 2px white, 2px 0 white, 0 -2px white",
                  }}
                >
                  {station.name}
                </div>
              </div>
            )
          ),
          anchor: { x: 50, y: atStation ? 50 : 44 },
        },
      })
    })

    // 도착지 마커
    new naver.maps.Marker({
      map,
      position: toNaverLatLng(receiverLocation),
      icon: {
        content: ReactDOMServer.renderToStaticMarkup(
          <div className="flex w-[100px] flex-col items-center">
            {delivered ? (
              <MapBubble color="RED" text="배송완료" />
            ) : (
              <MapBubble color="GRAY" text="도착지" />
            )}
            <div
              className={`rounded-full border-4 bg-white shadow-md ${
                delivered ? "border-red" : "border-gray-3"
              }`}
            >
              {delivered ? <LoadIcon /> : <div className="h-3 w-3" />}
            </div>
          </div>
        ),
        anchor: { x: 50, y: delivered ? 50 : 44 },
      },
    })

    // 광역 정류소 - 지역 정류소 실선
    for (let index = 0; index < steps.length - 1; index++) {
      const from = steps[index]
      const to = steps[index + 1]
      drawRouteBetweenStations(
        map,
        from.station,
        to.station,
        to.arrivalTime ? "FILLED" : from.departureTime ? "HALF" : "EMPTY"
      )
    }

    // 출발지 마커, 출발지 - 픽업지 점선
    if (senderLocation) {
      new naver.maps.Marker({
        map,
        position: toNaverLatLng(senderLocation),
        icon: {
          content: ReactDOMServer.renderToStaticMarkup(
            <div className="flex w-[100px] flex-col items-center">
              <MapBubble color="BLUE" text="출발지" />
              <div className="border-blue rounded-full border-4 bg-white shadow-md">
                <div className="h-3 w-3" />
              </div>
            </div>
          ),
          anchor: { x: 50, y: 44 },
        },
      })

      new naver.maps.Polyline({
        map,
        path: [
          toNaverLatLng(senderLocation),
          toNaverLatLng(steps[0].station.location),
        ],
        strokeWeight: 2,
        strokeColor: TODAY_INDIGO,
        strokeStyle: "shortdash",
      })
    }

    // 마지막 정류소 - 도착지 점선
    if (delivered) {
      new naver.maps.Polyline({
        map,
        path: [
          toNaverLatLng(steps[steps.length - 1].station.location),
          toNaverLatLng(receiverLocation),
        ],
        strokeWeight: 2,
        strokeColor: TODAY_INDIGO,
        strokeStyle: "shortdash",
      })
    }

    const zoomInAndOut = async () => {
      await sleepAsync(100)
      map.zoomBy(1)
      await sleepAsync(10)
      map.zoomBy(-1)
    }
    zoomInAndOut()

    const reversedSteps = [...steps].reverse()
    const lastStep = reversedSteps[0]
    const arrivedIndex = reversedSteps.findIndex((step) => !!step.arrivalTime)
    const currentIndex = arrivedIndex >= 0 ? steps.length - 1 - arrivedIndex : 0
    if (!lastStep) return
    const arrivedAtLastStation = !!lastStep.arrivalTime
    const inDelivery = !!lastStep.departureTime && !delivered

    // Fit
    const { southWest, northEast } = getEnclosingBounds(
      delivered || currentIndex === 0
        ? [...steps.map((step) => step.station.location), receiverLocation]
        : arrivedAtLastStation || steps.length === 1
        ? [lastStep.station.location, receiverLocation]
        : steps[currentIndex]
        ? [
            steps[currentIndex].station.location,
            steps[currentIndex + 1].station.location,
          ]
        : [steps[0].station.location, steps[1].station.location]
    )
    // 디자인 상, 지도 하단에 다른 버튼들이 있기에 bottom에 추가적인 margin을 넣어줌.
    map.fitBounds(
      new naver.maps.LatLngBounds(
        toNaverLatLng(southWest),
        toNaverLatLng(northEast)
      ),
      { top: 15 + 10 + 115, right: 15, bottom: 15 + 95, left: 15 }
    )
  }, [isMapLoaded, receiverLocation, steps, delivered, holdingDelivery])
  return (
    <div id="map" className="h-full w-full">
      <Script
        src="https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=ankumrnrbv"
        onLoad={() => setMapLoaded(true)}
      />
    </div>
  )
}

function getEnclosingBounds(locations: Location[]): {
  southWest: Location
  northEast: Location
} {
  const latitudes = locations.map((location) => location.latitude)
  const longitudes = locations.map((location) => location.longitude)
  return {
    southWest: {
      latitude: Math.min(...latitudes),
      longitude: Math.min(...longitudes),
    },
    northEast: {
      latitude: Math.max(...latitudes),
      longitude: Math.max(...longitudes),
    },
  }
}

function toNaverLatLng(location: Location): naver.maps.LatLng {
  return new naver.maps.LatLng(location.latitude, location.longitude)
}

async function drawRouteBetweenStations(
  map: naver.maps.Map,
  from: Station,
  to: Station,
  type: "FILLED" | "HALF" | "EMPTY"
) {
  const { data } = await axios
    .get<RouteCoordinatesResponse>(`/api/routes/from/${from.id}/to/${to.id}`)
    .catch(() => ({
      data: {
        coordinates: [],
      } as RouteCoordinatesResponse,
    }))
  const { coordinates, reversed } = data
  const route = [
    from.location,
    ...(reversed ? coordinates.reverse() : coordinates).map(
      (coords: number[]) => {
        const longitude = coords[0]
        const latitude = coords[1]
        return { latitude, longitude }
      }
    ),
    to.location,
  ]
  switch (type) {
    case "FILLED":
      new naver.maps.Polyline({
        map,
        path: route.map((location) => toNaverLatLng(location)),
        strokeWeight: 4,
        strokeColor: "#081236",
      })
      break
    case "EMPTY":
      new naver.maps.Polyline({
        map,
        path: route.map((location) => toNaverLatLng(location)),
        strokeWeight: 4,
        strokeColor: "#8A8A8A",
      })
      break
    case "HALF":
      const middleIndex = Math.floor(route.length / 2)
      const middle = route[middleIndex]
      new naver.maps.Polyline({
        map,
        path: route
          .slice(0, middleIndex + 1)
          .map((location) => toNaverLatLng(location)),
        strokeWeight: 4,
        strokeColor: "#081236",
      })
      new naver.maps.Polyline({
        map,
        path: route
          .slice(middleIndex)
          .map((location) => toNaverLatLng(location)),
        strokeWeight: 4,
        strokeColor: "#8A8A8A",
      })
      new naver.maps.Marker({
        map,
        position: toNaverLatLng(middle),
        icon: {
          content: ReactDOMServer.renderToStaticMarkup(
            <div className="flex w-[100px] flex-col items-center">
              <MapBubble color="GREEN" text="이동중" />
              <DeliIcon />
            </div>
          ),
          anchor: { x: 50, y: 50 },
        },
      })
      break
  }
}
