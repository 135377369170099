import { ButtonHTMLAttributes } from "react"

type CtaButtonProps = {
  children?: React.ReactNode
  onClick?: () => void
  disabled?: boolean
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"]
}

export function CtaButton({
  children,
  onClick,
  disabled = false,
  type,
}: CtaButtonProps) {
  return (
    <button
      className={`bg-blue flex h-14 w-full items-center justify-center rounded-lg text-base font-bold text-white ${
        disabled ? "cursor-default opacity-20" : ""
      }`}
      onClick={disabled ? undefined : onClick}
      type={type}
    >
      {children}
    </button>
  )
}
