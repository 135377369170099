import { Load } from "@today/api/tracker"
import { ProgressBarItem } from "."

export type ProgressBarProps = {
  loadState: Load["state"]
  pickUpTime: string | null
  shippingTime: string | null
  deliveryStartTime: string | null
  deliveryTime: string | null
  holdingDelivery: boolean
}

export function ProgressBar({
  loadState: state,
  pickUpTime,
  shippingTime,
  deliveryStartTime,
  deliveryTime,
  holdingDelivery,
}: ProgressBarProps) {
  const showsTimestamp = !!(
    pickUpTime ||
    shippingTime ||
    deliveryStartTime ||
    deliveryTime
  )
  const step =
    deliveryTime || state === "DELIVERED"
      ? 4
      : deliveryStartTime || state === "IN_DELIVERY"
      ? 3
      : shippingTime || state === "IN_TRANSPORT"
      ? 2
      : pickUpTime || state === "IN_COLLECT"
      ? 1
      : 0
  return (
    <div>
      <div className="flex justify-between px-6">
        <ProgressBarItem
          timestamp={pickUpTime}
          showsTimestamp={showsTimestamp}
          description="픽업완료"
          color={holdingDelivery ? "DARK_GRAY" : step >= 1 ? "BLUE" : "GRAY"}
        />
        <ProgressBarItem
          timestamp={shippingTime}
          showsTimestamp={showsTimestamp}
          description="배송시작"
          color={holdingDelivery ? "DARK_GRAY" : step >= 2 ? "BLUE" : "GRAY"}
        />
        <ProgressBarItem
          timestamp={deliveryStartTime}
          showsTimestamp={showsTimestamp}
          description="배송중"
          color={holdingDelivery ? "DARK_GRAY" : step >= 3 ? "BLUE" : "GRAY"}
        />
        <ProgressBarItem
          timestamp={deliveryTime}
          showsTimestamp={showsTimestamp}
          description="배송완료"
          color={step >= 4 ? "BLUE" : "GRAY"}
        />
      </div>
      <div className="mx-4 h-[18px] -translate-y-[46px]">
        <div className="bg-gray-0 h-full w-full rounded-full" />
        <div
          className={`absolute left-0 top-0 h-full rounded-full ${
            holdingDelivery
              ? "w-[80%] bg-[#A1A6AD]"
              : `bg-blue ${
                  step === 4
                    ? "w-full"
                    : step === 3
                    ? "w-[78%]"
                    : step === 2
                    ? "w-[52%]"
                    : step === 1
                    ? "w-[26%]"
                    : "w-0"
                }`
          }`}
        />
      </div>
    </div>
  )
}
