type RadioInputProps = {
  value: string
  onValueChange(value: string): void
  placeholder?: string
}

export function RadioInput({
  value,
  onValueChange,
  placeholder,
}: RadioInputProps) {
  return (
    <input
      className="border-indigo placeholder-gray-2 placeholder-shown:border-gray-2 rounded-md border p-4 text-lg"
      value={value}
      onChange={(e) => onValueChange(e.target.value)}
      placeholder={placeholder}
    />
  )
}
