import { Order } from "@today/api/taker"
import { Load } from "@today/api/tracker"
import { track } from "@vercel/analytics"
import axios from "axios"
import dayjs from "dayjs"
import Head from "next/head"
import Image from "next/image"
import Link from "next/link"
import { useState } from "react"
import useSWR from "swr"
import { Confetti } from "@today/ui/components/Confetti"
import {
  FormattedTitle,
  LoadDetailBottomSheet,
  Map,
  ProgressBar,
  ProgressBarProps,
  ProgressDetailBottomSheet,
  ProgressStepsProps,
  SearchSection,
  SubActionButton,
  TrackResultBottomButton,
} from "."
import { Campaign } from "../../pages/api/campaigns/random"
import DeliveredPackageIcon from "../../public/images/delivered-package-icon.svg"
import TrackResultDetailIcon from "../../public/images/track-result-detail-icon.svg"
import TrackResultPackageIcon from "../../public/images/track-result-package-icon.svg"

type TrackResultProps = {
  title: string
  description?: string
  load: Load
  order: Order
  progress: ProgressBarProps
  steps: ProgressStepsProps
}

export function TrackResult({
  title,
  description,
  load,
  order,
  progress,
  steps,
}: TrackResultProps) {
  const [isSearchVisible, setSearchVisible] = useState(false)
  const [isProgressDetailVisible, setProgressDetailVisible] = useState(false)
  const [isLoadDetailVisible, setLoadDetailVisible] = useState(false)
  const delivered = !!progress.deliveryTime
  const products = load.name.split("\n")
  const productName =
    products.length === 1
      ? products[0]
      : `${products[0]} 외 ${products.length - 1}개`
  const statusName = getStatusName(progress)
  const { data: campaign } = useSWR<Campaign>(`/api/campaigns/random`)
  // const [campaign, setCampaign] = useState<Campaign>()
  return (
    <div className="flex h-full flex-col">
      <Head>
        <title>배송조회 - Today</title>
      </Head>
      <div className="relative z-10 flex flex-col bg-white">
        <div className="mt-6 flex items-center justify-between px-6">
          <div className="whitespace-pre-line text-[1.375rem] font-bold sm:whitespace-nowrap">
            {title && (
              <FormattedTitle
                title={title}
                emphasisClassName="underline underline-offset-4 text-red"
              />
            )}
          </div>
          {delivered && (
            <span className="right-6 -translate-y-2">
              <DeliveredPackageIcon />
            </span>
          )}
        </div>
        {description && (
          <div className="text-gray-3 mb-3 mt-[0.375rem] px-6 text-sm">
            {description}
          </div>
        )}
        {load.state === "HOLDING_DELIVERY" ? (
          <span className="mb-4 ml-6">
            <Link
              href={`/track/${load.invoiceNumber}/unhold-delivery`}
              passHref
            >
              <SubActionButton
                onClick={() => track("track-result/unhold-delivery")}
              >
                해결하기 →
              </SubActionButton>
            </Link>
          </span>
        ) : (
          <div className="h-3" />
        )}
        <ProgressBar {...progress!} />
      </div>
      <div className="relative z-0 flex-1 overflow-y-hidden">
        <Map
          steps={steps.logs}
          holdingDelivery={load.state === "HOLDING_DELIVERY"}
          delivered={delivered}
          senderLocation={
            load.shippingType === "LM_TO_LM" ? load.sender.location : undefined
          }
          receiverLocation={load.receiver.location}
          listeners={{
            onClick: () => track("track-result/map-click"),
            onDoubleClick: () => track("track-result/map-double-click"),
            onPinchEnd: () => track("track-result/map-pinch"),
            onDragEnd: () => track("track-result/map-drag"),
          }}
        />
        {campaign && (
          <div className="absolute top-0 z-10 flex w-full justify-center px-4 pt-2.5">
            <Link href={campaign.url}>
              <a
                className="max-w-[400px]"
                onClick={() =>
                  axios.post(`/api/campaigns/log/click`, {
                    campaignId: campaign.id,
                    timestamp: new Date().toISOString(),
                  })
                }
              >
                <Image
                  src={campaign.image}
                  alt="광고"
                  width={1029}
                  height={294}
                  className="overflow-hidden rounded-lg"
                  onLoad={() =>
                    axios.post(`/api/campaigns/log/view`, {
                      campaignId: campaign.id,
                      timestamp: dayjs().toISOString(),
                    })
                  }
                />
              </a>
            </Link>
          </div>
        )}
        <div className="sticky bottom-0 z-10 flex gap-x-2 px-4 pb-[30px]">
          <button className="flex-1" onClick={() => setLoadDetailVisible(true)}>
            <TrackResultBottomButton
              icon={<TrackResultPackageIcon />}
              title="물품 정보"
              description={productName}
              onClick={() => track("track-result/load-detail")}
            />
          </button>
          <button
            className="flex-1"
            onClick={() => setProgressDetailVisible(true)}
          >
            <TrackResultBottomButton
              icon={<TrackResultDetailIcon />}
              title="배송 상세"
              description={statusName}
              onClick={() => track("track-result/delivery-progress")}
            />
          </button>
        </div>
      </div>
      {delivered && <Confetti />}
      {/* 다른 운송장번호 검색 */}
      <div
        className={`absolute bottom-0 top-12 z-50 w-full overflow-hidden transition-all ${
          isSearchVisible ? "visible" : "invisible"
        }`}
      >
        <div
          className={`absolute h-full w-full cursor-pointer bg-black transition duration-300 ${
            isSearchVisible ? "bg-opacity-80" : "bg-opacity-0"
          }`}
          onClick={() => setSearchVisible(false)}
        />
        <div
          className={`absolute top-0 w-full transition duration-300 ${
            isSearchVisible ? "translate-y-0" : "-translate-y-full"
          }`}
        >
          <SearchSection
            title={"다른 물품은\n어디까지 왔는지\n알아볼까요?"}
            onSubmit={() => setSearchVisible(false)}
          />
        </div>
      </div>
      <LoadDetailBottomSheet
        load={load}
        order={order}
        open={isLoadDetailVisible}
        onDismiss={() => setLoadDetailVisible(false)}
      />
      <ProgressDetailBottomSheet
        progressSteps={steps}
        title={statusName}
        open={isProgressDetailVisible}
        onDismiss={() => setProgressDetailVisible(false)}
      />
    </div>
  )
}

function getStatusName(progress: ProgressBarProps): string {
  const {
    loadState: state,
    pickUpTime,
    shippingTime,
    deliveryStartTime,
    deliveryTime,
  } = progress
  if (deliveryTime || state === "DELIVERED") return "배송완료"
  if (deliveryStartTime || state === "IN_DELIVERY") return "배송중"
  if (shippingTime || state === "IN_TRANSPORT") return "배송시작"
  if (pickUpTime || state === "IN_COLLECT") return "픽업완료"
  return "물품 준비중"
}
