import BackIcon from "../../public/images/back-icon.svg"
import { CtaButton } from "../CtaButton"

type PrivacyAgreementProps = {
  isOpen: boolean
  onClose: () => void
  onAccept: () => void
}

export function PrivacyAgreement({
  isOpen,
  onClose,
  onAccept,
}: PrivacyAgreementProps) {
  return (
    <div
      className={`fixed left-0 top-0 z-50 flex h-full w-full flex-col overflow-hidden bg-white px-6 pb-10 transition-all duration-300 ${
        isOpen ? "" : "invisible translate-x-full"
      }`}
    >
      <button
        className="mt-3 flex h-6 w-6 items-center justify-center"
        onClick={onClose}
      >
        <BackIcon />
      </button>
      <div className="mt-7 text-[22px] font-bold">이용약관</div>
      <div className="mt-6 text-lg font-medium">정보내용</div>
      <div className="mt-2">건물 출입방법 및 배송 요청사항</div>
      <div className="mt-6 text-lg font-medium">수집 이용 목적</div>
      <div className="mt-2">배송 물품 전달 및 수거</div>
      <div className="mt-6 text-lg font-medium">보유 및 이용 기간</div>
      <div className="mt-2">3개월</div>
      <div className="flex-1" />
      <CtaButton onClick={onAccept}>이용약관에 동의합니다</CtaButton>
    </div>
  )
}
