import { Load, Station } from "@today/api/tracker"
import dayjs from "dayjs"

type StationLog = {
  station: Station
  arrivalTime: string | null
  departureTime: string | null
}

export type ProgressStepsProps = {
  logs: StationLog[]
  source: SourceStepProps
  destination: DestinationStepProps
  shippingType: Load["shippingType"]
}

export function ProgressSteps({
  logs,
  source,
  destination,
  shippingType,
}: ProgressStepsProps) {
  switch (shippingType) {
    case "LM_TO_LM":
      break
    case "STATION_TO_STATION":
      logs = logs.slice(1, -1)
      break
    case "LM_TO_STATION":
      logs = logs.slice(0, -1)
      break
    case "STATION_TO_LM":
      logs = logs.slice(1)
      break
  }
  return (
    <div className="relative overflow-hidden">
      <div className="py-2">
        <SourceStep {...source} />
        {logs.map(({ station, arrivalTime, departureTime }, index) => {
          const nextStep = logs[index + 1]
          const arrivedAtNextStation = nextStep
            ? !!nextStep.arrivalTime
            : destination.deliveryState === "DELIVERED"
          return (
            <Step
              key={station.id}
              station={station}
              arrivalTime={arrivalTime}
              departureTime={departureTime}
              lastStationDepartureTime={logs[index - 1]?.departureTime}
              arrivedAtNextStation={arrivedAtNextStation}
            />
          )
        })}
        <DestinationStep {...destination} />
      </div>
    </div>
  )
}

type StepProps = {
  station: Station
  arrivalTime: string | null
  departureTime: string | null
  lastStationDepartureTime: string | null
  arrivedAtNextStation: boolean
}

function Step({
  station,
  arrivalTime,
  departureTime,
  lastStationDepartureTime,
  arrivedAtNextStation,
}: StepProps) {
  const atStation = !!arrivalTime && !departureTime
  const showsArrivalDate =
    lastStationDepartureTime &&
    arrivalTime &&
    !dayjs(lastStationDepartureTime).isSame(arrivalTime, "day")
  const showsDepartureDate =
    arrivalTime &&
    departureTime &&
    !dayjs(arrivalTime).isSame(departureTime, "day")
  return (
    <div>
      <div className="flex overflow-visible px-6 py-3">
        <div className="flex flex-col items-center overflow-visible">
          <div className="mt-1 flex h-4 w-4">
            {atStation && (
              <div className="bg-indigo absolute h-4 w-4 animate-ping rounded-full opacity-75" />
            )}
            <div
              className={`relative flex h-4 w-4 items-center justify-center rounded-full ${
                arrivalTime ? "bg-indigo" : "bg-gray-1"
              }`}
            >
              <div className="h-[5px] w-[5px] rounded-full bg-white" />
            </div>
          </div>
          {departureTime ? (
            <div className="bg-gray-1 -mb-6 mt-[5px] w-1 flex-1 rounded-full">
              <div
                className={`bg-blue w-1 rounded-full ${
                  arrivedAtNextStation ? "h-full" : "animate-route"
                }`}
              />
            </div>
          ) : (
            <div className="border-gray-1 -mb-6 mt-[5px] w-0 flex-1 rounded-full border-r-2 border-dashed" />
          )}
        </div>
        <div className="ml-3 flex flex-1 gap-x-2">
          <div className="flex flex-1 flex-col gap-y-[3px]">
            <span className="text-sm font-medium">
              {station ? `${station.name} 정류소` : ""}
            </span>
          </div>
          <div className="flex flex-col items-end gap-y-1">
            {arrivalTime ? (
              <span className="text-gray-3 text-sm">
                {dayjs(arrivalTime)
                  .locale("ko")
                  .format(
                    showsArrivalDate ? "MM.DD (dd) A h:mm" : "A h:mm"
                  )}{" "}
                도착
              </span>
            ) : lastStationDepartureTime ? (
              <span className="text-orange text-sm">정류소로 이동중</span>
            ) : (
              <TimestampPlaceholder />
            )}
            {departureTime ? (
              <span className="text-gray-3 text-sm">
                {dayjs(departureTime)
                  .locale("ko")
                  .format(
                    showsDepartureDate ? "MM.DD (dd) A h:mm" : "A h:mm"
                  )}{" "}
                출발
              </span>
            ) : arrivalTime ? (
              <span className="text-orange text-sm">이동 대기중</span>
            ) : (
              <TimestampPlaceholder />
            )}
          </div>
        </div>
      </div>
      <div className="bg-gray-0 ml-[3.75rem] mr-6 h-[1px]" />
    </div>
  )
}

type SourceStepProps = {
  title: string
  time: string | null
  actionName: string
  arrivedAtNextStation: boolean
}

function SourceStep({
  title,
  time,
  actionName,
  arrivedAtNextStation,
}: SourceStepProps) {
  return (
    <div>
      <div className="flex overflow-visible px-6 py-3">
        <div className="flex flex-col items-center overflow-visible">
          <div
            className={`mt-1 flex h-4 w-4 items-center justify-center rounded-full ${
              time ? "bg-indigo" : "bg-gray-1"
            }`}
          >
            <div className="h-[5px] w-[5px] rounded-full bg-white" />
          </div>
          {time ? (
            <div className="bg-gray-1 -mb-6 mt-[5px] w-1 flex-1 rounded-full">
              <div
                className={`bg-blue w-1 rounded-full ${
                  arrivedAtNextStation ? "h-full" : "animate-route"
                }`}
              />
            </div>
          ) : (
            <div className="border-gray-1 -mb-6 mt-[5px] w-0 flex-1 rounded-full border-r-2 border-dashed" />
          )}
        </div>
        <div className="ml-3 flex flex-1 gap-x-2">
          <div className="flex flex-1 flex-col gap-y-[3px]">
            <span className="text-sm font-medium">{title}</span>
          </div>
          <div className="flex flex-col items-end gap-y-1">
            {time ? (
              <>
                <span className="text-gray-3 text-sm">
                  {dayjs(time).locale("ko").format("MM.DD (dd) A h:mm")}{" "}
                  {actionName}
                </span>
                <TimestampPlaceholder />
              </>
            ) : (
              <>
                <TimestampPlaceholder />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="bg-gray-0 ml-[3.75rem] mr-6 h-[1px]" />
    </div>
  )
}

type DestinationStepProps = {
  title: string
  deliveryState: "NOT_STARTED" | "IN_DELIVERY" | "DELIVERED"
  deliveryTime: string | null
}

function DestinationStep({
  title,
  deliveryState,
  deliveryTime,
}: DestinationStepProps) {
  const notStarted = deliveryState === "NOT_STARTED"
  const delivered = deliveryState === "DELIVERED"
  return (
    <div className="flex overflow-visible px-6 py-4">
      <div className="flex flex-col items-center">
        <div
          className={`mt-0.5 flex h-4 w-4 items-center justify-center rounded-full ${
            delivered ? "bg-[#F26363]" : "bg-gray-2"
          }`}
        >
          <div className="h-[5px] w-[5px] rounded-full bg-white" />
        </div>
      </div>
      <div className="ml-3 flex flex-1">
        <div className="flex flex-1 flex-col gap-y-[3px]">
          <span
            className={`text-sm ${notStarted ? "text-gray-3" : "font-medium"}`}
          >
            {title}
          </span>
        </div>
        <div className="flex flex-col gap-y-[3px]">
          {deliveryState === "IN_DELIVERY" ? (
            <span className="text-orange text-sm">배송 중</span>
          ) : deliveryState === "DELIVERED" ? (
            <span className="text-blue text-sm font-semibold">
              {deliveryTime &&
                dayjs(deliveryTime).locale("ko").format("A h:mm")}{" "}
              배송 완료
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

function TimestampPlaceholder() {
  return <span className="invisible text-sm">.</span>
}
