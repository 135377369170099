import { ReactNode } from "react"

type LoadDetailItemProps = {
  title: string
  children: ReactNode
}

export function LoadDetailItem({ title, children }: LoadDetailItemProps) {
  return (
    <div className="flex items-center gap-x-6 p-6">
      <div className="text-gray-3 w-[88px]">{title}</div>
      <div className="flex-1">{children}</div>
    </div>
  )
}

export function Divider() {
  return <div className="bg-gray-1 mx-6 h-[0.5px]" />
}
