import { Load } from "@today/api/tracker"
import { formatInvoiceNumber, maskString } from "@today/lib"
import Head from "next/head"
import {
  Divider,
  LoadDetailItem,
  NavBar,
  ProgressSteps,
  ProgressStepsProps,
} from ".."

type ReturningProps = {
  load: Load
  steps: ProgressStepsProps
  originalInvoiceNumber?: string
}

export function LMCollect({
  load,
  steps,
  originalInvoiceNumber,
}: ReturningProps) {
  const { sender, receiver, invoiceNumber } = load
  return (
    <>
      <Head>
        <title>배송조회 - Today</title>
      </Head>
      <NavBar />
      <div className="flex flex-col">
        <div className="mt-4 whitespace-pre-line px-6 text-[1.375rem] font-bold">
          {load.deliveryClass === "RETURNING"
            ? "반품 접수가 완료되었어요"
            : "수거 접수가 완료되었어요"}
        </div>
        <div className="text-gray-3 mb-3 mt-[0.375rem] px-6 text-sm">
          {load.deliveryClass === "RETURNING"
            ? "반품지로 물품을 반송하고 있어요."
            : "목적지로 물품을 전달하고 있어요."}
        </div>
        <ProgressSteps {...steps} />
        <div className="bg-gray-0 h-2" />
        <div className="flex flex-col">
          <LoadDetailItem title="보낸이">
            {sender.shippingPlaceId ? sender.name : maskString(sender.name)}
          </LoadDetailItem>
          <Divider />
          <LoadDetailItem title="받는이">
            {receiver.shippingPlaceId
              ? receiver.name
              : maskString(receiver.name)}
          </LoadDetailItem>
          <Divider />
          <LoadDetailItem title="송장번호">
            {formatInvoiceNumber(invoiceNumber)}
          </LoadDetailItem>
          {originalInvoiceNumber && (
            <LoadDetailItem title="(원)송장번호">
              {formatInvoiceNumber(originalInvoiceNumber)}
            </LoadDetailItem>
          )}
        </div>
      </div>
    </>
  )
}
