import dayjs from "dayjs"
import "dayjs/locale/ko"

type ProgressBarItemProps = {
  timestamp: string | null
  showsTimestamp: boolean
  description: string
  color: "GRAY" | "BLUE" | "DARK_GRAY"
}

export function ProgressBarItem({
  timestamp,
  showsTimestamp,
  description,
  color,
}: ProgressBarItemProps) {
  const formattedTime = timestamp
    ? dayjs(timestamp).locale("ko").format("MM.DD.\nA h:mm")
    : ""
  let dotColor: string
  switch (color) {
    case "GRAY":
      dotColor = "#C0BEBE"
      break
    case "BLUE":
      dotColor = "#004AA0"
      break
    case "DARK_GRAY":
      dotColor = "#636B78"
      break
  }
  return (
    <div className="z-10 flex flex-col items-center">
      <div
        className={`text-gray-3 mb-1 whitespace-pre-line text-center text-xs ${
          showsTimestamp ? "h-9" : "h-0"
        }`}
      >
        {formattedTime}
      </div>
      <div
        className="mb-[13px] mt-[5px] h-2 w-2 rounded-full"
        style={{ backgroundColor: dotColor }}
      />
      <div
        className={`text-sm font-medium ${
          color === "GRAY" ? "text-gray-2" : "text-black"
        }`}
      >
        {description}
      </div>
    </div>
  )
}
