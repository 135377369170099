import { track } from "@vercel/analytics"
import Link from "next/link"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { NavBarButton } from "."
import BackIcon from "../../public/images/back-icon.svg"
import CloseIcon from "../../public/images/close-icon.svg"
import Logo from "../../public/images/logo.svg"

type NavBarProps = {
  title?: string
  trailing?: React.ReactNode
  backButton?: boolean
  onClickBackButton?(): void
}

export function NavBar({
  title,
  trailing = [],
  backButton = false,
  onClickBackButton,
}: NavBarProps) {
  const router = useRouter()
  const [isMenuVisible, setMenuVisible] = useState(false)
  const [canGoBack, setCanGoBack] = useState(false)
  useEffect(() => setCanGoBack(history.length > 2), [])
  return (
    <>
      {/* Menu Bar */}
      <div className="sticky top-0 z-50 flex h-12 w-full items-center bg-white px-6">
        {backButton ? (
          (onClickBackButton || canGoBack) && (
            <NavBarButton onClick={onClickBackButton ?? router.back}>
              <BackIcon />
            </NavBarButton>
          )
        ) : (
          <Link href="/">
            <a
              className="flex h-6 items-center justify-center"
              onClick={() => track("nav-bar/logo")}
            >
              <Logo />
            </a>
          </Link>
        )}
        <div className="flex-1" />
        {trailing}
        <span className="absolute left-1/2 mx-auto -translate-x-1/2 text-base font-semibold">
          {title}
        </span>
      </div>
      {/* Dropdown Menu */}
      <div
        className={`absolute top-0 z-50 h-full w-full overflow-hidden transition-all ${
          isMenuVisible ? "visible" : "invisible"
        }`}
      >
        <div
          className={`absolute h-full w-full cursor-pointer bg-black transition duration-300 ${
            isMenuVisible ? "bg-opacity-80" : "bg-opacity-0"
          }`}
          onClick={() => setMenuVisible(false)}
        />
        <div
          className={`absolute top-0 flex w-full flex-col bg-white transition duration-300 ${
            isMenuVisible ? "translate-y-0" : "-translate-y-full"
          }`}
        >
          <div className="flex h-12 w-full items-center justify-between px-6">
            <NavBarButton onClick={() => setMenuVisible(false)}>
              <CloseIcon />
            </NavBarButton>
            Today
          </div>
          <div className="h-80" />
        </div>
      </div>
    </>
  )
}
