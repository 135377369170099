import CheckedIcon from "../../public/images/radio-checked-icon.svg"
import UncheckedIcon from "../../public/images/radio-unchecked-icon.svg"

type RadioButtonProps = {
  label: string
  selected?: boolean
  onClick?(): void
}

export function RadioButton({
  label,
  selected = false,
  onClick,
}: RadioButtonProps) {
  return (
    <button className="flex items-center gap-x-2" onClick={onClick}>
      <div className="p-1.5">
        {selected ? <CheckedIcon /> : <UncheckedIcon />}
      </div>
      <span>{label}</span>
    </button>
  )
}
