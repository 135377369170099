import { Order } from "@today/api/taker"
import { Load } from "@today/api/tracker"
import {
  formatInvoiceNumber,
  getForwardingInvoiceNumber,
  maskString,
} from "@today/lib"
import { track } from "@vercel/analytics"
import Link from "next/link"
import { Divider, LoadDetailItem } from "."
import CallIcon from "../../public/images/call-sender-icon.svg"
import CloseIcon from "../../public/images/close-icon.svg"

type LoadDetailBottomSheetProps = {
  load: Load
  order: Order
  open: boolean
  onDismiss?(): void
}

export function LoadDetailBottomSheet({
  load,
  order,
  open,
  onDismiss,
}: LoadDetailBottomSheetProps) {
  const { name, sender, receiver, invoiceNumber } = load
  const products = name.split("\n")
  const productName =
    products.length === 1
      ? products[0]
      : `${products[0]} 외 ${products.length - 1}개`
  return (
    <div
      className={`absolute top-0 z-50 h-full w-full overflow-hidden transition-all ${
        open ? "visible" : "invisible"
      }`}
    >
      <div
        className={`absolute h-full w-full cursor-pointer bg-black transition duration-300 ${
          open ? "bg-opacity-80" : "bg-opacity-0"
        }`}
        onClick={onDismiss}
      />
      <div
        className={`absolute bottom-0 w-full break-all rounded-t-2xl bg-white transition duration-300 ${
          open ? "translate-y-0" : "translate-y-full"
        }`}
      >
        <button className="absolute right-6 top-6" onClick={onDismiss}>
          <CloseIcon />
        </button>
        <div className="flex flex-col pb-4 pt-8">
          <div className="flex flex-col px-6">
            <div className="text-gray-3 text-lg">물품 정보</div>
            <div className="line-clamp-3 mt-2 text-2xl font-bold">
              {productName}
            </div>
          </div>
          <div className="bg-gray-0 mt-6 h-2" />
          <LoadDetailItem title="보낸이">
            <div className="flex items-center gap-x-2">
              <span>{sender.name}</span>
              {sender.phone.length > 1 && (
                <Link href={`tel:${sender.phone}`}>
                  <a
                    className="p-1"
                    onClick={() => track("track-result/call-sender")}
                  >
                    <CallIcon />
                  </a>
                </Link>
              )}
            </div>
          </LoadDetailItem>
          <Divider />
          <LoadDetailItem title="받는이">
            {maskString(receiver.name)}
          </LoadDetailItem>
          <Divider />
          <LoadDetailItem title="송장번호">
            {order.deliveryClass === "FORWARDING_24"
              ? formatInvoiceNumber(getForwardingInvoiceNumber(order))
              : formatInvoiceNumber(invoiceNumber)}
          </LoadDetailItem>
        </div>
      </div>
    </div>
  )
}
