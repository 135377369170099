export function FormattedTitle({
  title,
  emphasisClassName,
}: {
  title: string
  emphasisClassName: string
}) {
  const emphasized = title.match(/\<([^)]+)\>/g)?.pop()
  if (!emphasized) {
    return <span>{title}</span>
  }
  const startIndex = title.indexOf(emphasized)
  const endIndex = startIndex + emphasized.length
  return (
    <>
      <span>{title.slice(0, startIndex)}</span>
      <span className={emphasisClassName}>
        {title.slice(startIndex + 1, endIndex - 1)}
      </span>
      <span>{title.slice(endIndex)}</span>
    </>
  )
}
