import Head from "next/head"
import { NavBar, SearchSection } from ".."

type NotFoundProps = {
  invoiceNumber: string
}

export function NotFound({ invoiceNumber }: NotFoundProps) {
  const lastDigit = invoiceNumber[invoiceNumber.length - 1]
  const formattedInvoiceNumber = invoiceNumber.match(/.{4}/g)?.join("-")
  const josa = ["0", "1", "3", "6", "7", "8"].includes(lastDigit) ? "은" : "는"
  return (
    <>
      <Head>
        <title>배송조회 - Today</title>
      </Head>
      <NavBar />
      <SearchSection
        title={`${formattedInvoiceNumber}${josa}\n없는 운송장 번호예요`}
        description="정확한 번호인지 다시 한번 확인해 주세요."
        defaultInvoiceNumber={invoiceNumber}
      />
    </>
  )
}
