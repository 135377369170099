import { HtmlHTMLAttributes } from "react"
import HandleIcon from "../../public/images/handle-icon.svg"

type TrackResultBottomButtonProps = {
  icon: React.ReactNode
  title: string
  description: string
} & HtmlHTMLAttributes<HTMLDivElement>

export function TrackResultBottomButton({
  icon,
  title,
  description,
  ...props
}: TrackResultBottomButtonProps) {
  return (
    <div
      className="flex gap-x-2 rounded-lg bg-white py-2.5 pl-3 pr-5 shadow"
      {...props}
    >
      {icon}
      <div className="mt-0.5 flex flex-1 flex-col items-start gap-y-1">
        <div className="flex w-full items-center justify-between">
          <div className="text-sm font-semibold">{title}</div>
          <div className="-rotate-90">
            <HandleIcon />
          </div>
        </div>
        <div className="line-clamp-1 break-all text-left text-xs text-[#0A2942]">
          {description}
        </div>
      </div>
    </div>
  )
}
