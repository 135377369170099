import { forwardRef, PropsWithChildren } from "react"

type SubActionButtonProps = {
  onClick?: () => void
}

export const SubActionButton = forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<SubActionButtonProps>
>(function SubActionButton(props, ref) {
  return (
    <a
      className="text-orange bg-orange self-start rounded bg-opacity-10 p-2 text-xs font-medium"
      ref={ref}
      {...props}
    />
  )
})
