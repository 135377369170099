import React from "react"

type NavBarButtonProps = {
  children: React.ReactElement
  onClick?(): void
}

export function NavBarButton({ children, onClick }: NavBarButtonProps) {
  return (
    <button
      className="flex h-6 w-6 items-center justify-center"
      onClick={onClick}
    >
      {React.Children.only(children)}
    </button>
  )
}
