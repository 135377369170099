export function maskString(originalString: string): string {
  return originalString
    .split(" ")
    .map((word) => {
      if (word.length === 0) {
        return ""
      }
      if (word.length === 1) {
        return "*"
      }
      if (word.length === 2) {
        return word[0] + "*".repeat(word.length - 1)
      }
      return word[0] + "*".repeat(word.length - 2) + word[word.length - 1]
    })
    .join(" ")
}
